.header {
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
    min-width: 1000px;
    background: rgba(255, 255, 255, .7);
    z-index: 99;
}
.header .header-content {
    position: relative;
    width: 1000px;
    margin: 0 auto;
    padding: 0 20px;
    z-index: 9;
}
.header-content .tabs {
    display: flex;
    align-items: center;
    height: 76px;
}
.header-content .tabs .tab-item:first-child {
    margin-right: 40px;
}
.tab-item {
    height: 100%;
    display: flex;
    align-items: center;
}
.tab-item.tab {
    padding: 0 40px;
    font-size: 16px;
    color: #000;
    font-weight: bolder;
    cursor: pointer;
}
.tab-item.tab:hover {
    position: relative;
    color: #0079FF;
}
.tab-item.tab:hover:after{
    position: absolute;
    content: '';
    width: 80px;
    height: 3px;
    background: #0079FF;
    left: 50%;
    bottom: 16px;
    transform: translateX(-50%);
}

.tab-item.tab:hover a {
    color: #0079FF!important;
}
.tab-item.call {
    flex: 1;
    display: flex;
    justify-content: end;
}
.header-content .tabs .tab-item:last-child {
    margin-right: 0;
}
.header-content .tabs .tab-item > a {
    height: 100%;
    color: #000;
    display: flex;
    align-items: center;
}
.header-content .tabs .tab-item > a img {
    width: 104px;
    height: 46px;
}

.header-content .btn-call {
    width: 112px;
    height: 30px;
    font-size: 14px;
}
.header-content .btn-wx {
    width: 148px;
    height: 30px;
    margin-right: 16px;
    font-size: 14px;
}
.header-content .btn-wx a {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    color: #0079FF;
}
.header-content .btn-wx span {
    vertical-align: baseline;
}
.header-content .btn-wx img {
    width: 18px;
    height: 15px;
    margin-right: 2px;
}

.tab-popup {
    display: none;
    position: absolute;
    width: 100%;
    height: 320px;
    min-width: 960px;
    left: 0;
    top: 77px;
    background: #fff;
    box-shadow: 0 3px 18px 0 rgba(0, 0, 0, .1);
    animation: slideToBottom .3s both;
}
@keyframes slideToBottom {
    0% {
        opacity: 0;
        transform: translateY(-76px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}
.popup-left {
    position: absolute;
    width: 33.3%;
    left: 0;
    top: 0;
    height: 320px;
    background: #fff;
}
.tab-desc {
    position: absolute;
    width: 240px;
    height: 320px;
    right: 0;
    top: 0;
    padding: 32px 24px 0;
}
.tab-desc .title {
    font-size: 16px;
    font-weight: bold;
    line-height: 26px;
}
.tab-desc .desc {
    margin-top: 8px;
    color: #212B36;
    line-height: 24px;
    font-size: 14px;
}
.popup-right {
    position: absolute;
    width: 66.7%;
    height: 320px;
    right: 0;
    top: 0;
    padding: 32px 24px;
    background: #F7F9FC;
    display: flex;
}
.list-wrap {
    width: 240px;
}
.list-item {
    margin-bottom: 32px;
}
.list-item > .title {
    font-size: 16px;
    margin-bottom: 8px;
    font-weight: bold;
}
.list-line {
    margin-bottom: 4px;
    display: flex;
    align-items: center;
    line-height: 24px;
}
.list-line.bold {
    font-weight: bold;
}
.list-line a {
    font-size: 14px;
    color: #000;
}
.list-line a:hover {
    color: #0079FF;
}
.list-line > .icon {
    margin-left: 4px;
    width: 16px;
    height: 16px;
    background: url(../imgs/arrow-small.png) center / cover;
}

.qrcode-wrap {
    position: fixed;
    width: 390px;
    padding: 64px 0 68px;
    left: 50%;
    top: 45%;
    transform: translate(-50%, -50%);
    background: #fff;
    border-radius: 2px;
    border: 1px #E2E2E2 solid;
    box-shadow: 0px 3px 8px 0px rgba(0,0,0,0.05);
    z-index: 99;
}
.qrcode-wrap .close {
    position: absolute;
    width: 35px;
    height: 35px;
    right: 10px;
    top: 10px;
    cursor: pointer;
    background: url(../imgs/icon-close.png) center / 15px 15px no-repeat;
}
.qrcode-wrap .title {
    font-size: 28px;
    line-height: 32px;
    text-align: center;
    font-weight: bold;
}
.qrcode-wrap .qrcode {
    margin: 22px auto 0;
    width: 192px;
    height: 192px;
    background: url(../imgs/qrcode.jpg) center / cover;
}

.qrcode-wrap .desc {
    width: 286px;
    margin: 26px auto 0;
    font-size: 18px;
    color: #212B36;
    line-height: 28px;
    font-weight: bold;
}
